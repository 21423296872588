#menu-main {
	list-style: none;
}

.menu-main-container {
    text-align: center;

    a {
		line-height: 2;
		font-family: $primaryFont;
		text-decoration: none;
		position: relative;
		display: inline-block;

		&:after {
			left: -20px;
			top: 20px;
			width: calc( 100% + 40px);
			height: 2px;
			content: '';
			position: absolute;
			background: $orange;
			opacity: 0;
			transition: .2s;
		}
    }

    a:hover:after,
    .current_page_item a:after {
		opacity: 1;
		top: 17px;
    }

    @include below($s) {

    	a {
    		line-height: 1.5;
    	}
    }
}

.nav-holder {
	width: 50%;
	height: 100vh;
	position: fixed;
	right: 0;
	background: $background;

	.menu-main-container {
	    @include vertical-align();

	    a {
			font-size: 60px;
			color: $blue;

			&:after {
				left: -50px;
				top: 73px;
				width: calc( 100% + 100px);
				height: 5px;
			}
	    }

	    a:hover:after,
	    .current_page_item a:after {
			top: 68px;
	    }
	}

    @include below($xl) {

    	.menu-main-container {

	    	a {
				font-size: 50px;
				line-height: 1.5;
			
				&:after {
					top: 49px;
				}
		    }

		    a:hover:after,
		    .current_page_item a:after {
				top: 44px;
		    }
    	}
    }

	@include below($l) {
		display: none;
	}
}

.mobile-menu {
	position: relative;

	.menu-icon {
	    position: fixed;
	    top: 5px;
	    right: 5px;
	    width: 34px;
	    height: 27px;
	    z-index: 11;
	    padding: 5px;

	    .hamburger {
	    	@include vertical-align(absolute);
	    	width: 24px;
	    	height: 3px;
	    	background: rgba(0,0,0,.9);
	    	transition: .2s;

	    	&:before, &:after {
	    		content: '';
	    		position: absolute;
	    		top: -7px;
	    		width: 24px;
	    		height: 3px;
	    		background: rgba(0,0,0,.9);
	    	}

	    	&:after {
	    		top: auto;
	    		bottom: -7px;
	    	}
	    }
	}

	.menu-main-container {
		display: none;
	    background: $blue;
	    z-index: 10;
	    width: 100%;
	    height: 100vh;

	    ul {
	    	list-style: none;
	    	padding: 90px 0;
	    	@include vertical-align();
	    }

	    li {
	    	padding: 5px 0;
	    }

	    a {
	    	color: $light;
	    }
	}

	&.open {
	    position: fixed;
	    width: 100%;
	    z-index: 10;

		.menu-main-container {
			display: block;
		}

		.hamburger {
			background: $light;

	    	&:before {
	    		top: 0;
	    		background: $light;
	    	}

	    	&:after {
	    		bottom: 0;
	    		background: $light;
	    	}
		}
	}
}

.home .mobile-menu {

	.hamburger {
		display: none;
	}

	.menu-main-container {
		background: transparent;
		@include vertical-align(fixed);
	}

	a {
		font-size: 30px;

		&:after {
			top: 32px;
			height: 2px;
		}
    }

    a:hover:after,
    .current_page_item a:after {
		top: 35px;
    }
}


