.page-template-tmp-printmenu .wrapper {
	width: 100%;

	.holder {
	    width: 760px;
	    padding: 80px 200px 0 0;
	}

	.menu-section {
		margin-bottom: 160px;
		position: relative;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.dish:first-child:before {
		display: none;
	}

	// #drinks, #starters, #pizza, #deserts {
	// 	img {
	// 		display: none;
	// 	}
	// }

	#drinks {
		padding-top: 50px;

		img {
			position: absolute;
		    top: -140px;
		    right: -290px;
		    width: 400px;
		}
	}

	#starters {
		margin-top: 100px;

		img {
			position: absolute;
		    bottom: -460px;
		    right: -310px;
		    width: 440px;
			opacity: .3;
		}
	}

	#pizza {

		img {
			position: absolute;
		    top: -20px;
		    right: -290px;
		    width: 520px;
		}
	}

	#deserts {
		padding-top: 650px;

		img {
			position: absolute;
		    top: 100px;
		    right: -290px;
		    width: 520px;
			opacity: .3;
		}
	}
	
	.light {
		color: $dark;
	}

	#drinks .light, #deserts .light {
		color: $orange;
	}

	.price {
	    position: absolute;
	    right: -100px;
	    text-align: right;
	    bottom: 0;
	}
}


// For print

@media print {

	body{
		// padding-top: 30px;
	}

	@page {
		margin-left: 2cm;
	}

	#pizza {
		padding-top: 150px;
	}

	.menu-section {
		page-break-after: always;
	}
}