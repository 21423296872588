.wdi_feed_thumbnail_1 .wdi_photo_overlay:hover {
	background-color: rgba(255,255,255,.1) !important;
}

.wdi_feed_item,
.wdi_feed_thumbnail_1 .wdi_photo_wrap_inner {
	background-color: $blue !important;
	border-color: $blue !important; 

	@include below($l) {
		background-color: transparent !important;
		border-color: transparent !important; 	
	}
}

.wdi_feed_thumbnail_1 .wdi_feed_container,
.wdi_feed_theme_1 .wdi_feed_wrapper,
.wdi_load_more_wrap,
.wdi_feed_thumbnail_1 .wdi_load_more,
.wdi_feed_thumbnail_1 .wdi_spinner {
	padding: 0 !important;
	background-color: transparent !important;
	border-radius: 0 !important;
	height: auto !important;
	width: auto !important;
	border: none !important;
}

.wdi_feed_thumbnail_1 .wdi_spinner_wrap {
    border: none !important;
    background-color: transparent !important;
    height: 60px !important;
    width: 60px !important;
}

.wdi_feed_thumbnail_1 .wdi_load_more_spinner {
	color: $light !important;

	@include below($l) {
		color: $blue !important;
	}
}

.wdi_load_more_wrap {
	margin: $contentPadding 0 !important;
	display: block !important;
}

.wdi_load_more_text {
	color: $light !important;
	text-decoration: underline !important;

	@include below($l) {
		color: $blue !important;
	}
}
