.content {

	img {
		width: 450px;
		margin: 2*$contentPadding auto;
		display: block;
	}

	p {
		margin: 1.5*$contentPadding auto;
	}

	@include below($m) {

		img {
		    max-width: none !important;
		    width: 120%;
		    margin-left: -10%;
		}
	}
}