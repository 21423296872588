.button {
	border: 1px solid $dark;
	padding: 13px;
	max-width: 100%;
	text-decoration: none;
	text-align: center;
	font-family: $primaryFont;
	transition: .2s;

	&:hover {
		background: $dark;
		color: $light;
	}

	&.white {
		border-color: $light;
		color: $light;

		&:hover {
			background: $light;
			color: $dark;
		}
	}
}

.mobile-button {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	height: 38px;
	background: $blue;
	color: $light;
    text-decoration: none;
    text-align: center;
    padding: 7px 0;
    display: none;

    @include below($l) {
    	display: block;	
    }
}

.home .mobile-button {
	display: none !important;
}