.map {
	max-height: 310px;
	
	@include below($s) {

	    max-width: none !important;
	    width: 150%;
	    margin-left: -25%;
	}
}

.mb {
	margin-bottom: .5*$contentPadding;
}

.page-template-tmp-contact {

	.flex-row {
		margin-top: 2*$contentPadding;
	}

	.box {
		margin-right: 1.75*$contentPadding;
		width: auto !important;

		&:last-child {
			margin-right: 0 !important;
		}
	}

	@include below($xl) {

		.box {
			margin-right: 1.5*$contentPadding;
		}
	}

	@include below($m) {

		.flex-row {
			display: block;
		}

		.box {
			margin-right: 0;
			margin-bottom: $contentPadding;
		}
	}
}