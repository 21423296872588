.top-logo {
	@include horizontal-align(fixed);
	top: 1.5*$contentPadding;
	background-image: url('../img/kiosken_logo_black.svg');
    width: 162px;
    height: 161px;
    background-size: cover;
    z-index: 1;

	@include below($l) {
	    width: 78px;
	    height: 78px;
	    top: $contentPadding;
	    left: $contentPadding;
	    transform: none;
	    position: absolute;
	}
}

.home .top-logo {
	display: none;
}

.logo-holder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
}

.big-logo {
	width: 512px;
	@include align-both(absolute);

	@include below($l) {
	    width: 88px;
	    top: $contentPadding/2;
	    left: $contentPadding/2;
	    transform: none;
		position: fixed;
	}
}