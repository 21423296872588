@font-face {
    font-family: 'GT America Mono';
    src: url('../fonts/GTAmericaMono-Regular.woff2') format('woff2'),
        url('../fonts/GTAmericaMono-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GT America Mono';
    src: url('../fonts/GTAmericaMono-Medium.woff2') format('woff2'),
        url('../fonts/GTAmericaMono-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GT America Mono';
    src: url('../fonts/GTAmericaMono-Bold.woff2') format('woff2'),
        url('../fonts/GTAmericaMono-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

$primaryFont: 'GT America Mono', sans-serif;


// Standard
*,p {
    font-weight: $regular;
    font-family: $primaryFont;
    color: $dark;
    line-height: 1.4;
    font-size: 16px;
}

.content p {
    line-height: 1.75;
}


// Weights
.medium {
    font-weight: $medium;
}

.bold {
    font-weight: $bold;
}


// Sizes
.s {
    font-size: 16px;
}

.m {
    font-size: 18px;
}

.l {
    font-size: 35px;

    @include below($m) {
        font-size: 30px;
    }
}


// Styles
.uppercase {
    text-transform: uppercase;
}

.center {
    text-align: center;
}

.high {
    line-height: 30px;
}

.spacing {
    letter-spacing: 3px;
}

.blue {
    color: $blue;
}

.orange {
    color: $orange;
}

.light {
    color: $light;
}

.page-title {
    margin-bottom: 2*$contentPadding;

    @include below($m) {
        margin-bottom: $contentPadding;
    }
}

.sub-title {
    width: 700px;
    max-width: 100%;
    margin: 2*$contentPadding auto;

    @include below($m) {
        margin: $contentPadding auto;
    }
}

.preamble {
    width: 470px;
    max-width: 100%;
    margin: 0 auto 2*$contentPadding;
}

.wdi_load_more_text {
    font-family: $primaryFont;
}


// Links
a {
    display: block;
}