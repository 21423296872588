.dish {
	margin: 2*$contentPadding 0;
	position: relative;

	&:first-child {
		margin-top: 0;

		&:before {
			content: '';
			position: absolute;
			background-size: cover;
		}
	}
}

.snacks {
	padding-bottom: 100px;
	.dish:first-child {

		&:before {
			width: 35px;
			height: 75px;
			opacity: 0.7;
			background-image: url(../img/snacks-n2.svg);
			top: -55px;
			left: -30px;
			transform: rotate(230deg);

			@include below($s) {
				left: -95px;
			}
		}
	}
}

.starters .dish:first-child {

	&:before {
	    width: 73px;
	    height: 109px;
	    background-image: url(../img/starters.svg);
	    top: -65px;
	    left: -40px;
		transform: rotate(220deg);

		@include below($s) {
			left: -35px;
		}
	}
}

.pizza {
	padding-top: 130px;

	.dish:first-child {

		&:before {
			width: 119px;
			height: 109px;
			background-image: url('../img/pizza.svg');
			top: -80px;
			left: -80px;
		}


		@include below($xl) {

			&:before {
				width: 100px;
				height: 92px;
				top: -65px;
				left: -40px;
			}
		}

		@include below($l) {

			&:before {
			    transform: rotate(83deg);
			    left: auto;
			    right: -30px;
			    top: -60px;
			}
		}
	}
}

.page-template-tmp-takeaway .pizza {
	padding-top: 0;
}

.deserts {
	padding-top: 110px;

	.dish:first-child {

		&:before {
		    width: 135px;
		    height: 95px;
		    background-image: url(../img/desert.svg);
		    top: -55px;
		    left: -100px;
			transform: rotate(230deg);

			@include below($s) {
				left: -95px;
			}
		}
	}
}

hr {
	width: 100%;
	height: 1px;
	border: none;
	background: $light;
	margin: 2*$contentPadding 0 3*$contentPadding;

	@include below($l) {
		display: none;
	}
}

.page-template-tmp-takeaway,
.page-template-tmp-menu {

	.holder {
		padding-top: 130px;
	}

	@include below($l) {

		.light {
			color: $dark !important;
		}

		.button {
			display: none;
		}
	}
}