@import 'normalize';
@import 'entypo';
@import 'breaker';
@import 'mixins';

// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$contentPadding: 20px;
$contentMaxWidth: 90%;

// Font weights.
$regular: 400;
$medium: 500;
$bold: 700;

// Colors.
$light: #FFF;
$dark: #000;
$orange: #C4760B;
$blue: #1A4045;
$background: rgba(229,213,200,.5);
$mobileBackground: rgba(229,213,200,.3);

@import 'fonts';
@import 'buttons';
@import 'logo';
@import 'nav';
@import 'menu';
@import 'gallery';
@import 'page';
@import 'contact';
@import 'printmenu';


body {
	background: $light;
}

.wrapper {
	background: $light;
	width: 50%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    @include below($l) {
    	width: 100%;
    	background: $mobileBackground;
    }
}

.home .wrapper {
	background-color: $blue;
	
	&:after {
		content: "";
		background-image: url('../img/pattern_small.svg');
		opacity: 0.08;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		position: absolute;
	}
}

.page-template-tmp-gallery .wrapper {
	background: $blue;

	@include below($l) {
    	width: 100%;
    	background: $mobileBackground;
    }
}

.page-template-tmp-menu .wrapper,
.page-template-tmp-takeaway .wrapper {
	background: $orange;

	@include below($l) {
    	width: 100%;
    	background: $mobileBackground;
    }
}

.holder {
	position: relative;
	width: 560px;
	max-width: $contentMaxWidth;
	margin: 0 auto;
	padding: 4*$contentPadding 0;

	img {
		max-width: 100%;
		height: auto;
	}

	@include below($xl) {
		width: 450px;
	}

	@include below($l) {
		width: 580px;
	}

	@include below($m) {
		padding: 150px 0 4*$contentPadding;
		max-width: 80%;
	}
}

.page-template-tmp-contact .holder {

	@include below($s) {
		width: 260px;		
	}
}

.flex-row  {
	display: flex;
	margin-bottom: 2*$contentPadding;

	.box {
		width: 50%;
	}

	@include below($s) {
	    display: block;
	    margin-bottom: 0;

	    .box {
	    	width: 100%;
	    	margin: 2*$contentPadding 0;
	    }
	}
}

.center-box {
	width: 200px;
	margin: 0 auto;
	text-align: center;

	img {
		width: 130px;
		margin-bottom: $contentPadding;
	}
}

.mobile-only {
	display: none;

	@include below($l) {
		display: block;
	}
}

.info-holder {
    width: 80%;
    max-width: 560px;
    @include horizontal-align();
    margin-top: 90vh;
    z-index: 10;

	@include below($s) {
		width: 95%;
		margin-top: calc( 100vh - 50px );
	}
}

.featured-info {
	background: #E5D5C8; 
	margin-bottom: $contentPadding; 
	padding: 1.5*$contentPadding;

	@include below($s) {
		padding: $contentPadding;		
	}
}